'use client';
import {Suspense} from 'react';
import LoadingShell from 'features/generic/components/LoadingShell/LoadingShell';
import App from 'features/app/components/App/App';

export default function IndexPage() {
    return (
        <Suspense fallback={<LoadingShell />}>
            <App />
        </Suspense>
    );
}

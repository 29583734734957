import React from 'react';

import ContentHeader from 'features/generic/components/ContentHeader/ContentHeader';
import Content from 'features/generic/components/Content/Content';
import Loading from 'features/generic/components/Loading/Loading';

import {SidebarContainer, ContentContainer, Inner} from './styles';

const LoadingShell = (props) => {
    return (
        <div>
            <SidebarContainer />
            <ContentContainer>
                <Inner>
                    <ContentHeader empty />
                    <Content>
                        <Loading />
                    </Content>
                </Inner>
            </ContentContainer>
        </div>
    );
};

export default LoadingShell;

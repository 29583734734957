import React, {Suspense} from 'react';
import LoadingShell from 'features/generic/components/LoadingShell/LoadingShell';
import Dashboard from 'features/dashboard/components/Dashboard/Dashboard';

export default (props) => {
    return (
        <Suspense fallback={<LoadingShell />}>
            <Dashboard />
        </Suspense>
    );
};
